<template>
  <div>
    <div v-if="ordersFetched" class="container">
      <Breadcrumb class="bg-transparent" :breadcrumbList="[
        { 'name': 'Admin Dashboard', 'link': $router.resolve({ name: 'admin' }).href },
        { 'name': 'Orders' }
      ]"/>
      <div class="main-container">
        <h1 class="mb-4 pb-1"><strong>Manage Orders</strong></h1>
        <div class="container">
          <div class="col-xl-12 mb-0 content-box">
            <div class="container pt-4">
              <div class="row d-flex justify-content-between px-3 mb-2">
                <div class="font-weight-bold d-none d-sm-block">
                  Filter By Date And Status
                </div>
                <div v-if="paginationLoad" class="w-auto">
                  Total Orders: <span class="font-weight-bold">{{ paginationTotal }}</span>
                </div>
              </div>
              <b-row class="d-flex align-items-center pb-2">
                <b-col cols="12" md="6" lg="2">
                  <b-form-datepicker id="from-datepicker" :date-format-options="dateOptions" v-model="fromDate" class="mb-3 mb-lg-0" placeholder="'From' date"></b-form-datepicker>
                </b-col>
                <b-col cols="12" md="6" lg="2">
                  <b-form-datepicker id="to-datepicker" :date-format-options="dateOptions" v-model="toDate" class="mb-3 mb-lg-0" placeholder="'To' date"></b-form-datepicker>
                </b-col>
                <b-col cols="12" md="2" lg="2">
                  <b-form-select v-model="dateOptionsInput" :options="dateSearchOptions" class="mb-3 mb-md-0"></b-form-select>
                </b-col>
                <b-col cols="12" md="2" lg="2">
                  <b-form-select v-model="status" :options="statuses" class="mb-3 mb-md-0"></b-form-select>
                </b-col>
                <b-col cols="12" md="2" lg="2">
                  <b-form-select v-model="paymentType" :options="paymentTypes" id="paymentFilter" class="mb-3 mb-md-0"></b-form-select>
                </b-col>
                <b-col cols="12" md="2" lg="2">
                  <b-form-select v-model="includeDuplicates" :options="duplicatesOptions" class="mb-3 mb-md-0"></b-form-select>
                </b-col>
              </b-row>
              <b-row class="d-flex align-items-center pb-2 pt-2">
                <b-col cols="12" md="4" lg="4">
                  <b-button variant="danger" class="w-100 mb-2 mb-md-0" @click="clearFilters">Clear Filter</b-button>
                </b-col>
                <b-col cols="12" md="4" lg="4">
                  <b-button variant="primary" class="w-100 mb-2 mb-md-0" @click="getOrders(1)">Apply Filter</b-button>
                </b-col>
                <b-col cols="12" md="4" lg="4">
                  <b-button v-if="!exporting" variant="secondary" class="w-100" @click="exportData">Export Data</b-button>
                  <b-button v-else variant="secondary" disabled="disabled" block class="w-100">
                    <b-spinner  small label="Small Spinner"></b-spinner>
                  </b-button>
                </b-col>
              </b-row>
              <b-row class="d-flex align-items-center pb-2 mt-2 ">
                <b-col cols="12" md="8" lg="9">
                  <b-input-group class="w-100">
                    <b-form-input class="nav-searchbar" v-model="searchOrdersInput" ref="searchOrdersInput" name="searchOrdersInputBox" :placeholder="searchPlaceholder"></b-form-input>
                    <b-input-group-append>
                      <b-button variant="primary" size="sm" type="submit" id="ordersSearchButton" @click="getOrders(1)">
                        <div class="d-flex justify-content-center align-items-center px-3">
                          <i class="fa-solid fa-magnifying-glass mr-2"></i>
                          <span class="pl-1 h6 m-0 font-weight-normal">Search</span>
                        </div>
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-col>
                <b-col cols="12" md="4" lg="3" >
                  <b-form-select v-model="searchOptionsInput" :options="searchOptions" class="mb-3 mb-md-0"></b-form-select>
                </b-col>
              </b-row>
              <b-alert v-model="searchError" variant="primary">
                You need to select a search option before searching
              </b-alert>
              <div v-if="paginationLoad" class="pt-2 overflow-auto">
                <table v-if="paginationTotal > 0"
                       class="table table-striped table-bordered add-manage-table"
                       data-filter="#filter" data-filter-text-only="true">
                  <thead>
                  <tr>
                    <th>Purchased</th>
                    <th>Completed</th>
                    <th>Completion Time</th>
                    <th>Order Number</th>
                    <th>Seller</th>
                    <th>Buyer</th>
                    <th>Task</th>
                    <th>Price</th>
                    <th>Status</th>
                    <th>Payment Type</th>
                    <th>Gift Card Code</th>
                    <th>Campaign</th>
                    <th>Seller IP</th>
                    <th>Buyer IP</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(order, index) in orders" :key="index" class="mb-md-0 mb-2">
                    <td data-title="Purchased"> {{date(order[0].date_created) }}</td>
                    <td data-title="Completed"> {{dateCompleted(order[0].date_completed) }}</td>
                    <td>
                      {{order.time_taken_to_completed !== null ? order.time_taken_to_completed + ' hours' : 'NA' }}
                    </td>
                    <td data-title="Order Number" v-if="roleOrderDetails">
                      <router-link class="text-dark  " :to="'/admin/orders/' + order[0].hash" target="_blank">
                        {{order[0].hash}}
                      </router-link>
                    </td>
                    <td data-title="Order Number" v-else>{{order[0].hash}}</td>
                    <td data-title="Seller">
                      <router-link class="text-dark  " :to="'/admin/users/' + order[0].seller_user.hash" target="_blank">
                        {{order[0].seller_username}}
                      </router-link>
                      <br />
                      <span class="small">{{order[0].seller_user.electroneum_id}}</span>
                    </td>
                    <td data-title="Buyer">
                      <router-link class="text-dark" :to="'/admin/users/' + order[0].buyer_user.hash" target="_blank">
                        {{order[0].buyer_username}}
                      </router-link>
                    </td>
                    <td data-title="Task">
                      <router-link class="text-dark" :to="'/' + order[0].seller_username + '/' + order[0].items[0].gig_slug" target="_blank">
                        {{order[0].items[0].gig_title}}
                      </router-link>
                    </td>
                    <td data-title="Price">${{(order[0].price_total.toFixed(2) !== '0.00') ? order[0].price_total.toFixed(2) : order[0].price_subtotal.toFixed(2)}}</td>
                    <td data-title="Status">
                      <span v-if="order[0].status[order[0].status.length - 1].status === 0" class="p-0">
                        Waiting acceptance
                      </span>
                      <span v-else-if="order[0].status[order[0].status.length - 1 ].status === 1" class="p-0">
                        In Progress
                      </span>
                      <span v-else-if="order[0].status[order[0].status.length - 1].status === 2" class="p-0">
                        Declined & Refunded
                      </span>
                      <span v-else-if="order[0].status[order[0].status.length - 1].status === 3" class="p-0">
                        In Progress
                      </span>
                      <span v-else-if="order[0].status[order[0].status.length - 1].status === 4" class="p-0">
                        Delivered
                      </span>
                      <span v-else-if="order[0].status[order[0].status.length - 1].status === 5" class="p-0">
                        Completed
                      </span>
                      <span v-else-if="order[0].status[order[0].status.length - 1].status === 6" class="p-0">
                        Refunded
                      </span>
                      <span v-else-if="order[0].status[order[0].status.length - 1].status === 7" class="p-0">
                        Disputed
                      </span>
                      <span v-else-if="order[0].status[order[0].status.length - 1].status === 8" class="p-0">
                        Cancelled
                      </span>
                      <span v-else-if="order[0].status[order[0].status.length - 1].status === 9" class="p-0">
                        Expired
                      </span>
                      <span v-else-if="order[0].status[order[0].status.length - 1].status === 10" class="p-0">
                        Duplicate
                      </span>
                      <span v-else class="p-0">
                        In progress
                      </span>
                    </td>
                    <td>
                      {{order[0].payments[0].payment_gateway.display_name}}
                    </td>
                    <td>
                      {{order.gift_card_code !== null ? order.gift_card_code : 'NA' }}
                    </td>
                    <td>
                      {{order.campaign !== null ? order.campaign : 'NA' }}
                    </td>
                    <td>
                      {{order[0].seller_ip !== null ? order[0].seller_ip : 'NA' }}
                    </td>
                    <td>
                      {{order[0].buyer_ip !== null ? order[0].buyer_ip : 'NA' }}
                    </td>
                  </tr>
                  </tbody>
                </table>
                <div v-else>
                  <p>No orders found matching your filter.</p>
                </div>
              </div>
              <div v-if="paginationLoad" class="pb-3">
                <Pagination :perPage="paginationPerPage" :currentPage="currentPage" :total="paginationTotal"
                            v-on:paginationUpdate="getOrders" v-if="paginationTotal > paginationPerPage"
                            class="mt-3" />
              </div>
              <div v-else class="mx-auto py-5">
                <b-spinner large label="Large Spinner"></b-spinner>
                <div>Loading Orders</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="mx-auto py-5">
      <b-spinner large label="Large Spinner"></b-spinner>
      <div>Loading Orders</div>
    </div>
  </div>
</template>

<script>
import Pagination from '../../components/Global/Pagination'
import ApiService from '../../apiService'
import Breadcrumb from '../../components/Breadcrumbs/Breadcrumb'

export default {
  name: 'AdminOrders',
  components: {
    Breadcrumb,
    Pagination
  },
  data () {
    return {
      ordersFetched: false,
      paginationLoad: false,
      exporting: false,
      paginationTotal: 0,
      orders: [],
      currentPage: 1,
      paginationPerPage: 10,
      fromDate: '',
      toDate: '',
      searchInput: '',
      searchOrdersInput: '',
      searchOptionsInput: null,
      dateOptionsInput: null,
      searchError: false,
      searchPlaceholder: 'Search using the search option selected',
      status: null,
      paymentType: null,
      paymentTypes: [
        { value: null, text: 'Please select a payment type' },
        { value: 'stripe', text: 'Card Payments' },
        { value: 'etn', text: 'ETN Payments' },
        { value: 'dummy', text: 'Dummy Payments' },
        { value: 'giftcard', text: 'Gift Card Payments' },
        { value: 'crypto', text: 'Crypto Payments' }
      ],
      statuses: [
        { value: null, text: 'Please select a status' },
        { value: 0, text: 'Waiting Acceptance' },
        { value: 1, text: 'Accepted' },
        { value: 2, text: 'Declined' },
        { value: 3, text: 'In Progress' },
        { value: 4, text: 'Delivered' },
        { value: 5, text: 'Completed' },
        { value: 6, text: 'Refunded' },
        { value: 7, text: 'Disputed' },
        { value: 8, text: 'Cancelled' },
        { value: 9, text: 'Expired' },
        { value: 10, text: 'Duplicates' }
      ],
      searchOptions: [
        { value: null, text: 'Search Options' },
        { value: 1, text: ' Order (Username, Hash, Task)' },
        { value: 2, text: 'Gift card campaign' },
        { value: 3, text: 'User IP' }
      ],
      dateOptions: {
        'year': 'numeric',
        'month': 'numeric',
        'day': 'numeric'
      },
      duplicatesOptions: [
        { value: false, text: 'Do not include duplicate orders' },
        { value: true, text: 'Include duplicate orders' }
      ],
      dateSearchOptions: [
        { value: null, text: 'Date Search Options' },
        { value: 1, text: 'Date Purchased' },
        { value: 2, text: 'Date Completed' }
      ],
      includeDuplicates: false,
      roleOrderDetails: false
    }
  },
  mounted () {
    this.getOrders(1)
  },
  methods: {
    date (date) {
      if (date === null) {
        return 'Awaiting payment confirmation'
      }
      const newDate = new Date(date)
      return newDate.toLocaleDateString()
    },
    dateCompleted (date) {
      if (date === null) {
        return 'NA'
      }
      const newDate = new Date(date)
      return newDate.toLocaleDateString()
    },
    getOrders (page) {
      this.paginationLoad = false

      let filter = this.createFilters()
      if (filter !== null) {
        ApiService.getAllOrders(page, filter, this.paginationPerPage).then(response => {
          this.orders = response.data.data.results
          this.currentPage = response.data.data.pagination.page
          this.paginationPerPage = response.data.data.pagination.per_page
          this.paginationTotal = response.data.data.pagination.total
          this.paginationLoad = true
          this.ordersFetched = true
          this.roleOrderDetails = this.$store.getters.hasRole('ROLE_ADMIN_VIEW_ORDER_DETAILS')
        }).catch(() => {
        })
      }
    },
    createFilters () {
      this.searchError = false
      let data = {
        'giftCardSwitch': true
      }

      if (this.searchOrdersInput !== '' && this.searchOptionsInput === null) {
        this.searchError = true
        this.paginationLoad = true
        this.ordersFetched = true
        return null
      }

      if (this.searchOrdersInput !== '' && this.searchOptionsInput === 1) {
        data.terms = this.searchOrdersInput
      }

      if (this.searchOrdersInput !== '' && this.searchOptionsInput === 2) {
        data.campaign = this.searchOrdersInput
      }

      if (this.searchOrdersInput !== '' && this.searchOptionsInput === 3) {
        data.user_ip = this.searchOrdersInput
      }

      if (this.dateOptionsInput !== null && this.dateOptionsInput === 2) {
        if (this.fromDate !== '') {
          data.fromDateCompleted = this.fromDate
        }

        if (this.toDate !== '') {
          data.toDateCompleted = this.toDate
        }
      } else {
        if (this.fromDate !== '') {
          data.fromDate = this.fromDate
        }

        if (this.toDate !== '') {
          data.toDate = this.toDate
        }
      }

      if (this.status !== null) {
        data.status = this.status
      }

      if (this.paymentType !== null) {
        data.paymentType = this.paymentType
      }

      if (this.includeDuplicates) {
        data.duplicates = this.includeDuplicates
      }

      return data
    },
    clearFilters () {
      this.searchError = false
      if (this.status !== null || this.fromDate !== '' || this.toDate !== '' || this.searchOrdersInput !== '' || this.paymentType !== null || this.searchOptionsInput !== null || this.includeDuplicates) {
        this.status = null
        this.paymentType = null
        this.fromDate = ''
        this.toDate = ''
        this.searchOrdersInput = ''
        this.searchOptionsInput = null
        this.includeDuplicates = false
        this.getOrders()
      }
    },
    exportData () {
      this.exporting = true
      let filter = this.createFilters()
      if (filter !== null) {
        ApiService.exportOrders(filter).then(response => {
          let date = new Date()
          let month = date.getMonth() + 1
          let monthFormat = month < 10 ? '0' + month : month
          let dateFormat = date.getFullYear() + '-' + monthFormat + '-' + date.getDate() + '-' + date.getHours() + '-' + date.getMinutes()

          let url = window.URL.createObjectURL(new Blob([response.data]))
          let link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'orders-' + dateFormat + '.csv')
          document.body.appendChild(link)
          link.click()
          this.exporting = false
        }).catch(() => {
          this.exporting = false
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.content-box {
  overflow: unset !important;
}
.table {
  font-size: 10px;
  overflow-x: auto;
  @include md-up {
    overflow: hidden;
  }
  @include lg-up {
    font-size: 13px;
  }
}
.container {
  max-width: 100%;
}
</style>
<style>
#to-datepicker, #from-datepicker {
  padding-right: 0;
}
</style>
